<template>
  <div style="width: 100%; height: 60vh; overflow-y: auto">
    <div
      class="card card-custom gutter-b"
      style="width: 100%; height: 60vh; overflow-y: auto"
    >
      <div class="card-header border-0 py-5">
        <h3 class="card-title font-weight-bolder text-dark">
          Report By Location:
          <span class="ml-5"
            ><span
              class="label label-lg label-inline label-light-primary font-weight-bold"
              >{{ range.start | formatDate }}</span
            ></span
          >
          <span
            ><span class="flex-shrink-0 my-2 mx-3"
              ><i class="la la-arrow-right"></i></span
          ></span>
          <span
            ><span
              class="label label-lg label-inline label-light-primary font-weight-bold"
              >{{ range.end | formatDate }}</span
            ></span
          >
        </h3>
      </div>
      <div class="card-body pt-0">
        <LocationReportTable
          :traffic="traffic"
          :selectedLocations="selectedLocations"
          :isTokenColumnVisible="true"
        >
        </LocationReportTable>
      </div>
    </div>
  </div>
</template>

<script>
import LocationReportTable from '@/view/content/components/location-report-table/LocationReportTable'

export default {
  name: 'ByLocationTraffic',
  props: {
    traffic: {
      type: Array,
    },
    selected: {
      type: Array,
    },
    selectedLocations: {
      type: Array,
    },
    range: {
      type: Object,
    },
  },
  components: {
    LocationReportTable,
  },
}
</script>

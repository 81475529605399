<template>
  <div>
    <div class="d-flex">
      <div class="card-toolbar mb-2 mr-2">
        <button
          @click="toggleAdvancedView"
          class="btn btn-light-primary font-weight-bold px-10"
        >
          {{
            isAdvancedView
              ? $t('GENERAL.SIMPLE_VIEW')
              : $t('GENERAL.ADVANCED_VIEW')
          }}
        </button>
      </div>
      <div class="card-toolbar mb-2">
        <button
          @click="toggleLocationsWithoutTraffic"
          class="btn btn-light-primary font-weight-bold px-10"
        >
          {{ isLocationsWithoutTrafficVisible ? 'Hide' : 'Show' }} locations
          without traffic
        </button>
      </div>
    </div>
    <div class="table-responsive mb-0 pb-0">
      <table
        v-if="isGenerated"
        class="table table-head-custom table-vertical-center table-head-bg"
      >
        <thead>
          <tr class="text-uppercase">
            <th class="pl-6">
              <span @click="orderByLocationName" class="cursor-pointer">
                <span
                  class="pt-2"
                  :class="{ 'text-primary': locationNameOrder.isActive }"
                >
                  {{ $t('COMPANIES.LOCATION_NAME') }}
                </span>
                <span
                  class="svg-icon svg-icon-sm pl-2"
                  :class="{
                    'svg-icon-primary': locationNameOrder.isActive,
                  }"
                >
                  <inline-svg
                    v-if="locationNameOrder.query === 'asc'"
                    src="media/svg/icons/Navigation/Down-2.svg"
                  />
                  <inline-svg
                    v-else
                    src="media/svg/icons/Navigation/Up-2.svg"
                  />
                </span>
              </span>
            </th>
            <th
              v-if="currentUserCompanyModule[0] === 'gaming'"
              class="text-right"
            >
              {{ $t('GENERAL.LESSOR') }}
            </th>
            <th
              v-if="
                (currentUserCompanyModule[0] === 'gaming' ||
                  currentUserCompanyModule[0] === 'parking') &&
                isAdvancedView
              "
              class="text-right"
            >
              Netto Coin
            </th>
            <th
              v-if="currentUserCompanyModule[0] === 'gaming'"
              class="text-right cursor-pointer"
            >
              <span @click="orderByCoin" class="cursor-pointer">
                <span
                  class="pt-2"
                  :class="{ 'text-primary': coinOrder.isActive }"
                >
                  <span v-b-tooltip.hover="'Brutto Coin'">{{
                    $t('GENERAL.COIN')
                  }}</span></span
                >
                <span
                  class="svg-icon svg-icon-sm pl-2"
                  :class="{
                    'svg-icon-primary': coinOrder.isActive,
                  }"
                >
                  <inline-svg
                    v-if="coinOrder.query === 'asc'"
                    src="media/svg/icons/Navigation/Down-2.svg"
                  />
                  <inline-svg
                    v-else
                    src="media/svg/icons/Navigation/Up-2.svg"
                  />
                </span>
              </span>
            </th>
            <th
              v-if="currentUserCompanyModule[0] === 'gaming' && isAdvancedView"
              class="text-right"
            >
              Netto SMS
            </th>
            <th
              v-if="currentUserCompanyModule[0] === 'gaming'"
              class="text-right cursor-pointer"
            >
              <span @click="orderBySms" class="cursor-pointer">
                <span
                  class="pt-2"
                  :class="{ 'text-primary': smsOrder.isActive }"
                >
                  <span v-b-tooltip.hover="'Brutto SMS'">SMS</span></span
                >
                <span
                  class="svg-icon svg-icon-sm pl-2"
                  :class="{
                    'svg-icon-primary': smsOrder.isActive,
                  }"
                >
                  <inline-svg
                    v-if="smsOrder.query === 'asc'"
                    src="media/svg/icons/Navigation/Down-2.svg"
                  />
                  <inline-svg
                    v-else
                    src="media/svg/icons/Navigation/Up-2.svg"
                  />
                </span>
              </span>
            </th>
            <th
              v-if="currentUserCompanyModule[0] === 'gaming' && isAdvancedView"
              class="text-right"
            >
              Netto Card
            </th>
            <th
              v-if="currentUserCompanyModule[0] === 'gaming'"
              class="text-right cursor-pointer"
            >
              <span @click="orderBySms" class="cursor-pointer">
                <span
                  class="pt-2"
                  :class="{ 'text-primary': smsOrder.isActive }"
                >
                  <span v-b-tooltip.hover="'Brutto Card'">Card</span></span
                >
                <span
                  class="svg-icon svg-icon-sm pl-2"
                  :class="{
                    'svg-icon-primary': smsOrder.isActive,
                  }"
                >
                  <inline-svg
                    v-if="smsOrder.query === 'asc'"
                    src="media/svg/icons/Navigation/Down-2.svg"
                  />
                  <inline-svg
                    v-else
                    src="media/svg/icons/Navigation/Up-2.svg"
                  />
                </span>
              </span>
            </th>
            <th
              v-if="currentUserCompanyModule[0] === 'gaming' && isAdvancedView"
              class="text-right"
            >
              Netto CSC
            </th>
            <th
              v-if="
                (currentUserCompanyModule[0] === 'gaming' ||
                  currentUserCompanyModule[0] === 'parking') &&
                isAdvancedView
              "
              class="text-right"
            >
              Pdv CSC
            </th>
            <th class="text-right cursor-pointer">
              <span @click="orderByCs" class="cursor-pointer">
                <span
                  class="pt-2"
                  :class="{ 'text-primary': cSOrder.isActive }"
                >
                  <span
                    v-if="currentUserCompanyModule[0] === 'gaming'"
                    v-b-tooltip.hover="'Brutto Coin + SMS + Card'"
                    >CSC</span
                  >
                  <span v-else v-b-tooltip.hover="'Brutto Total'">{{
                    $t('GENERAL.TOTAL')
                  }}</span>
                </span>
                <span
                  class="svg-icon svg-icon-sm pl-2"
                  :class="{
                    'svg-icon-primary': cSOrder.isActive,
                  }"
                >
                  <inline-svg
                    v-if="cSOrder.query === 'asc'"
                    src="media/svg/icons/Navigation/Down-2.svg"
                  />
                  <inline-svg
                    v-else
                    src="media/svg/icons/Navigation/Up-2.svg"
                  />
                </span>
              </span>
            </th>
            <th
              v-if="currentUserCompanyModule[0] === 'gaming'"
              class="text-right"
            >
              <span @click="orderByLoyalty" class="cursor-pointer">
                <span
                  class="pt-2"
                  :class="{ 'text-primary': loyaltyOrder.isActive }"
                >
                  <span>Loyalty</span></span
                >
                <span
                  class="svg-icon svg-icon-sm pl-2"
                  :class="{
                    'svg-icon-primary': loyaltyOrder.isActive,
                  }"
                >
                  <inline-svg
                    v-if="loyaltyOrder.query === 'asc'"
                    src="media/svg/icons/Navigation/Down-2.svg"
                  />
                  <inline-svg
                    v-else
                    src="media/svg/icons/Navigation/Up-2.svg"
                  />
                </span>
              </span>
            </th>
            <th
              v-if="
                isTokenColumnVisible && currentUserCompanyModule[0] === 'gaming'
              "
              class="text-right"
            >
              <span @click="orderByToken" class="cursor-pointer">
                <span
                  class="pt-2"
                  :class="{ 'text-primary': tokenOrder.isActive }"
                >
                  <span>Token</span></span
                >
                <span
                  class="svg-icon svg-icon-sm pl-2"
                  :class="{
                    'svg-icon-primary': tokenOrder.isActive,
                  }"
                >
                  <inline-svg
                    v-if="tokenOrder.query === 'asc'"
                    src="media/svg/icons/Navigation/Down-2.svg"
                  />
                  <inline-svg
                    v-else
                    src="media/svg/icons/Navigation/Up-2.svg"
                  />
                </span>
              </span>
            </th>
            <th
              v-if="currentUserCompanyModule[0] === 'gaming'"
              class="text-right cursor-pointer pr-6"
            >
              <span @click="orderByTotal" class="cursor-pointer">
                <span
                  class="pt-2"
                  :class="{ 'text-primary': totalOrder.isActive }"
                >
                  <span v-b-tooltip.hover="'Coin + SMS + Loyalty'">{{
                    $t('GENERAL.TOTAL')
                  }}</span></span
                >
                <span
                  class="svg-icon svg-icon-sm pl-2"
                  :class="{
                    'svg-icon-primary': totalOrder.isActive,
                  }"
                >
                  <inline-svg
                    v-if="totalOrder.query === 'asc'"
                    src="media/svg/icons/Navigation/Down-2.svg"
                  />
                  <inline-svg
                    v-else
                    src="media/svg/icons/Navigation/Up-2.svg"
                  />
                </span>
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(location, key) in data.filter(
              (d) => this.isLocationsWithoutTrafficVisible || d.total !== 0
            )"
            :key="key + '_' + key"
          >
            <td class="pl-6" :class="{ 'border-top-0': key === 0 }">
              <span class="text-dark-75 font-weight-bolder mb-1 font-size-lg">{{
                location.location
              }}</span>
            </td>
            <td
              v-if="currentUserCompanyModule[0] === 'gaming'"
              class="text-right"
              :class="{ 'border-top-0': key === 0 }"
            >
              {{ location.lessor }}
            </td>
            <td
              v-if="
                (currentUserCompanyModule[0] === 'gaming' ||
                  currentUserCompanyModule[0] === 'parking') &&
                isAdvancedView
              "
              :class="{ 'border-top-0': key === 0 }"
              class="text-right"
            >
              <span v-if="location.netCoin !== 0"
                >{{ location.netCoin | currencyFormat }}
              </span>
              <span v-else>0 €</span>
            </td>
            <td
              v-if="currentUserCompanyModule[0] === 'gaming'"
              :class="{ 'border-top-0': key === 0 }"
              class="text-right"
            >
              <span v-if="location.coin !== 0"
                >{{ location.coin | currencyFormat }}
              </span>
              <span v-else>0 €</span>
            </td>
            <td
              v-if="currentUserCompanyModule[0] === 'gaming' && isAdvancedView"
              :class="{ 'border-top-0': key === 0 }"
              class="text-right"
            >
              <span v-if="location.netSms !== 0"
                >{{ location.netSms | currencyFormat }}
              </span>
              <span v-else>0 €</span>
            </td>
            <td
              v-if="currentUserCompanyModule[0] === 'gaming'"
              :class="{ 'border-top-0': key === 0 }"
              class="text-right"
            >
              <span v-if="location.sms !== 0">{{
                location.sms | currencyFormat
              }}</span>
              <span v-else>0 €</span>
            </td>
            <td
              v-if="currentUserCompanyModule[0] === 'gaming' && isAdvancedView"
              :class="{ 'border-top-0': key === 0 }"
              class="text-right"
            >
              <span v-if="location.netCard !== 0"
                >{{ location.netCard | currencyFormat }}
              </span>
              <span v-else>0 €</span>
            </td>
            <td
              v-if="currentUserCompanyModule[0] === 'gaming'"
              :class="{ 'border-top-0': key === 0 }"
              class="text-right"
            >
              <span v-if="location.card !== 0">{{
                location.card | currencyFormat
              }}</span>
              <span v-else>0 €</span>
            </td>
            <td
              v-if="currentUserCompanyModule[0] === 'gaming' && isAdvancedView"
              class="text-right"
              :class="{ 'border-top-0': key === 0 }"
            >
              <span v-if="location.net !== 0">{{
                location.net | currencyFormat
              }}</span>
              <span v-else>0 €</span>
            </td>
            <td
              v-if="
                (currentUserCompanyModule[0] === 'gaming' ||
                  currentUserCompanyModule[0] === 'parking') &&
                isAdvancedView
              "
              class="text-right"
              :class="{ 'border-top-0': key === 0 }"
            >
              <span v-if="location.pdv !== 0">{{
                location.pdv | currencyFormat
              }}</span>
              <span v-else>0 €</span>
            </td>
            <td
              v-if="currentUserCompanyModule[0] === 'gaming'"
              :class="{ 'border-top-0': key === 0 }"
              class="text-dark-75 font-weight-bolder font-italic text-right pr-6"
            >
              <span v-if="location.csc !== 0">{{
                location.csc | currencyFormat
              }}</span>
              <span v-else>0 €</span>
            </td>
            <td
              v-if="currentUserCompanyModule[0] === 'gaming'"
              :class="{ 'border-top-0': key === 0 }"
              class="text-right"
            >
              <span v-if="location.loyalty !== 0"
                >{{ location.loyalty | currencyFormat }}
              </span>
              <span v-else>0 €</span>
            </td>
            <td
              v-if="
                isTokenColumnVisible && currentUserCompanyModule[0] === 'gaming'
              "
              :class="{ 'border-top-0': key === 0 }"
              class="text-right"
            >
              <span v-if="location.token !== 0">{{ location.token }} </span>
              <span v-else>0</span>
            </td>
            <td
              v-if="
                currentUserCompanyModule[0] === 'gaming' ||
                currentUserCompanyModule[0] === 'parking'
              "
              :class="{ 'border-top-0': key === 0 }"
              class="text-dark-75 font-weight-bolder font-italic text-right pr-6"
            >
              <span v-if="location.total !== 0"
                >{{ location.total }}
              </span>
              <span v-else>0 €</span>
            </td>
          </tr>
          <tr>
            <td
              class="pt-10 pl-6 font-size-lg font-weight-bolder text-uppercase"
            >
              {{ $t('GENERAL.TOTAL') }}:
            </td>

            <td v-if="currentUserCompanyModule[0] === 'gaming'"></td>
            <td
              v-if="
                (currentUserCompanyModule[0] === 'gaming' ||
                  currentUserCompanyModule[0] === 'parking') &&
                isAdvancedView
              "
              class="pt-10 font-size-lg text-right font-weight-bolder text-uppercase"
            >
              <span v-if="generateReportNetCoin !== 0"
                >{{ generateReportNetCoin | currencyFormat }}
              </span>
              <span v-else>0 €</span>
            </td>
            <td
              v-if="currentUserCompanyModule[0] === 'gaming'"
              class="pt-10 font-size-lg text-right font-weight-bolder text-uppercase"
            >
              <span v-if="generateReportCoin !== 0"
                >{{ generateReportCoin | currencyFormat }}
              </span>
              <span v-else>0 €</span>
            </td>
            <td
              v-if="currentUserCompanyModule[0] === 'gaming' && isAdvancedView"
              class="pt-10 font-size-lg text-right font-weight-bolder text-uppercase"
            >
              <span v-if="generateReportNetSms !== 0">{{
                generateReportNetSms | currencyFormat
              }}</span>
              <span v-else>0 €</span>
            </td>
            <td
              v-if="currentUserCompanyModule[0] === 'gaming'"
              class="pt-10 font-size-lg text-right font-weight-bolder text-uppercase"
            >
              <span v-if="generateReportSms !== 0"
                >{{ generateReportSms | currencyFormat }}
              </span>
              <span v-else>0 €</span>
            </td>
            <td
              v-if="currentUserCompanyModule[0] === 'gaming' && isAdvancedView"
              class="pt-10 font-size-lg text-right font-weight-bolder text-uppercase"
            >
              <span v-if="generateReportNetCard !== 0">{{
                generateReportNetCard | currencyFormat
              }}</span>
              <span v-else>0 €</span>
            </td>
            <td
              v-if="currentUserCompanyModule[0] === 'gaming'"
              class="pt-10 font-size-lg text-right font-weight-bolder text-uppercase"
            >
              <span v-if="generateReportCard !== 0"
                >{{ generateReportCard | currencyFormat }}
              </span>
              <span v-else>0 €</span>
            </td>

            <td
              v-if="currentUserCompanyModule[0] === 'gaming' && isAdvancedView"
              class="pt-10 font-size-lg text-right font-weight-bolder text-uppercase"
            >
              {{ generateReportNet | currencyFormat }}
            </td>
            <td
              v-if="
                (currentUserCompanyModule[0] === 'gaming' ||
                  currentUserCompanyModule[0] === 'parking') &&
                isAdvancedView
              "
              class="pt-10 font-size-lg text-right font-weight-bolder text-uppercase"
            >
              {{ generateReportPdv | currencyFormat }}
            </td>
            <td
              v-if="currentUserCompanyModule[0] === 'gaming'"
              class="pt-10 font-size-lg text-right font-weight-bolder text-uppercase"
            >
              {{ generateReportCsc | currencyFormat }}
            </td>
            <!-- <td
            class="pt-10 font-size-lg text-right font-weight-bolder text-uppercase pr-6"
          >
            {{ generateReportAmount | currencyFormat }}
          </td> -->
            <td
              v-if="currentUserCompanyModule[0] === 'gaming'"
              class="pt-10 font-size-lg text-right font-weight-bolder text-uppercase pr-6"
            >
              <span v-if="generateReportLoyalty !== 0">{{
                generateReportLoyalty | currencyFormat
              }}</span>
              <span v-else>0 €</span>
            </td>
            <td
              v-if="
                isTokenColumnVisible && currentUserCompanyModule[0] === 'gaming'
              "
              class="pt-10 font-size-lg text-right font-weight-bolder text-uppercase"
            >
              <span v-if="generateReportToken !== 0">{{
                generateReportToken
              }}</span>
              <span v-else>0</span>
            </td>
            <td
              v-if="
                currentUserCompanyModule[0] === 'gaming' ||
                currentUserCompanyModule[0] === 'parking'
              "
              class="pt-10 font-size-lg text-right font-weight-bolder text-uppercase pr-6"
            >
              <span v-if="generateReportTotal !== 0">{{
                generateReportTotal | currencyFormat
              }}</span>
              <span v-else>0 €</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'LocationReportTable',
  props: {
    traffic: { type: Array },
    selectedLocations: { type: Array },
    isTokenColumnVisible: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['currentUserCompanyOib', 'currentUserCompanyModule']),
  },
  data() {
    return {
      data: [],
      generateReportNetCoin: 0,
      generateReportCoin: 0,
      generateReportNetSms: 0,
      generateReportSms: 0,
      generateReportNetCard: 0,
      generateReportCard: 0,
      generateReportNetCsc: 0,
      generateReportCsc: 0,
      generateReportNet: 0,
      generateReportPdv: 0,
      generateReportAmount: 0,
      generateReportLoyalty: 0,
      generateReportToken: 0,
      generateReportTotal: 0,
      token: 0,
      locationNameOrder: { query: 'asc', isActive: false },
      coinOrder: { query: 'asc', isActive: false },
      smsOrder: { query: 'asc', isActive: false },
      cardOrder: { query: 'asc', isActive: false },
      cSOrder: { query: 'asc', isActive: false },
      loyaltyOrder: { query: 'asc', isActive: false },
      tokenOrder: { query: 'asc', isActive: false },
      totalOrder: { query: 'asc', isActive: false },
      isGenerated: false,
      isLocationsWithoutTrafficVisible: true,
      locationsWithoutTraffic: [],
      isAdvancedView: false,
    }
  },
  mounted() {
    const locations = this.selectedLocations.map((location) => ({
      name: location.title,
      lessor: (location.lessor && location.lessor.name) || '',
    }))

    this.locationsWithoutTraffic = locations.filter((location) => {
      return !this.traffic
        .map((transaction) => transaction.location)
        .includes(location.name)
    })

    this.traffic.forEach((transaction) => {
      let netCoin = 0
      if (
        transaction.paymentType === 'pay_coin' ||
        transaction.paymentType === 'pay_cctalk' ||
        transaction.paymentType === 'pay_mdb' ||
        transaction.paymentType === 'pay_sci'
      ) {
        netCoin = parseFloat(transaction.amount * 0.8)
        this.generateReportNetCoin += netCoin
      }

      let coin = 0
      if (
        transaction.paymentType === 'pay_coin' ||
        transaction.paymentType === 'pay_cctalk' ||
        transaction.paymentType === 'pay_mdb' ||
        transaction.paymentType === 'pay_sci'
      ) {
        coin = parseFloat(transaction.amount)
        this.generateReportCoin += coin
      }

      let netSms = 0
      if (transaction.paymentType === 'pay_sms') {
        netSms = parseFloat(transaction.amount * 0.8)
        this.generateReportNetSms += netSms
      }

      let sms = 0
      if (transaction.paymentType === 'pay_sms') {
        sms = parseFloat(transaction.amount)
        this.generateReportSms += sms
      }

      let netCard = 0
      if (transaction.paymentType === 'pay_card') {
        netCard = parseFloat(transaction.amount * 0.8)
        this.generateReportNetCard += netCard
      }

      let card = 0
      if (transaction.paymentType === 'pay_card') {
        card = parseFloat(transaction.amount)
        this.generateReportCard += card
      }

      let netCsc = 0
      if (
        transaction.paymentType === 'pay_coin' ||
        transaction.paymentType === 'pay_cctalk' ||
        transaction.paymentType === 'pay_mdb' ||
        transaction.paymentType === 'pay_sci' ||
        transaction.paymentType === 'pay_sms' ||
        transaction.paymentType === 'pay_card'
      ) {
        csc = parseFloat(transaction.amount * 0.8)
        this.generateReportNetCsc += netCsc
      }

      let csc = 0
      if (
        transaction.paymentType === 'pay_coin' ||
        transaction.paymentType === 'pay_cctalk' ||
        transaction.paymentType === 'pay_mdb' ||
        transaction.paymentType === 'pay_sci' ||
        transaction.paymentType === 'pay_sms' ||
        transaction.paymentType === 'pay_card'
      ) {
        csc = parseFloat(transaction.amount)
        this.generateReportCsc += csc
      }

      let net = 0
      if (
        transaction.paymentType === 'pay_coin' ||
        transaction.paymentType === 'pay_cctalk' ||
        transaction.paymentType === 'pay_mdb' ||
        transaction.paymentType === 'pay_sci' ||
        transaction.paymentType === 'pay_sms' ||
        transaction.paymentType === 'pay_card'
      ) {
        net = parseFloat(transaction.amount) * 0.8
        this.generateReportNet += net
      }

      let pdv = net * 0.25
      this.generateReportPdv += pdv

      let amount = 0
      if (
        transaction.paymentType === 'pay_coin' ||
        transaction.paymentType === 'pay_cctalk' ||
        transaction.paymentType === 'pay_mdb' ||
        transaction.paymentType === 'pay_sci' ||
        transaction.paymentType === 'pay_sms' ||
        transaction.paymentType === 'pay_card'
      ) {
        amount = parseFloat(transaction.amount)
        this.generateReportAmount += amount
      }

      let loyalty = 0
      if (transaction.paymentType === 'pay_rfcard') {
        loyalty = parseFloat(transaction.amount)
        this.generateReportLoyalty += loyalty
      }

      let token = 0
      if (transaction.paymentType === 'pay_token') {
        token = parseFloat(transaction.amount)
        this.generateReportToken += token
      }

      let total = parseFloat(transaction.amount).toFixed(2)
      this.generateReportTotal += total

      const row = this.data.findIndex(
        (location) => location.locationId === transaction.locationId
      )

      const col = this.data[row]

      if (col === undefined) {
        this.data.push({
          location: transaction.location,
          lessor: transaction.lessor,
          paymentType: transaction.paymentType,
          netCoin: netCoin,
          coin: coin,
          netSms: netSms,
          sms: sms,
          netCard: netCard,
          card: card,
          netCsc,
          csc,
          net: net,
          pdv: pdv,
          amount: amount,
          token: token,
          loyalty: loyalty,
          total: total,
          locationId: transaction.locationId,
        })
      } else {
        this.data[row].netCoin += netCoin
        this.data[row].coin += coin
        this.data[row].netSms += netSms
        this.data[row].sms += sms
        this.data[row].netCard += netCard
        this.data[row].card += card
        this.data[row].netCsc += netCsc
        this.data[row].csc += csc
        this.data[row].net += net
        this.data[row].pdv += pdv
        this.data[row].amount += amount
        this.data[row].token += token
        this.data[row].loyalty += loyalty
        this.data[row].total += total
      }
    })

    this.locationsWithoutTraffic.forEach((locationWithoutTraffic) => {
      this.data.push({
        location: locationWithoutTraffic.name,
        lessor: locationWithoutTraffic.lessor,
        paymentType: '',
        netCoin: 0,
        coin: 0,
        netSms: 0,
        sms: 0,
        netCard: 0,
        card: 0,
        netCsc: 0,
        csc: 0,
        net: 0,
        pdv: 0,
        amount: 0,
        loyalty: 0,
        token: 0,
        total: 0,
        locationId: locationWithoutTraffic.locationId,
      })
    })

    setTimeout(() => {
      this.isGenerated = true
    }, 1000)
  },
  methods: {
    toggleLocationsWithoutTraffic() {
      this.isLocationsWithoutTrafficVisible =
        !this.isLocationsWithoutTrafficVisible
    },
    toggleAdvancedView() {
      this.isAdvancedView = !this.isAdvancedView
    },
    orderByLocationName() {
      this.clearActiveFilters()
      this.locationNameOrder.isActive = true

      if (this.locationNameOrder.query === 'asc') {
        this.locationNameOrder.query = 'desc'
        this.data.sort((a, b) => {
          if (a.name < b.name) return -1
          if (a.name > b.name) return 1
          return 0
        })
      } else {
        this.locationNameOrder.query = 'asc'
        this.data.sort((a, b) => {
          if (a.name > b.name) return -1
          if (a.name > b.name) return 1
          return 0
        })
      }
    },
    orderByCoin() {
      this.clearActiveFilters()
      this.coinOrder.isActive = true

      if (this.coinOrder.query === 'asc') {
        this.coinOrder.query = 'desc'
        this.data.sort((a, b) => a.coin - b.coin)
      } else {
        this.coinOrder.query = 'asc'
        this.data.sort((a, b) => b.coin - a.coin)
      }
    },
    orderBySms() {
      this.clearActiveFilters()
      this.smsOrder.isActive = true

      if (this.smsOrder.query === 'asc') {
        this.smsOrder.query = 'desc'
        this.data.sort((a, b) => a.sms - b.sms)
      } else {
        this.smsOrder.query = 'asc'
        this.data.sort((a, b) => b.sms - a.sms)
      }
    },
    prderByCard() {
      this.clearActiveFilters()
      this.cardOrder.isActive = true

      if (this.cardOrder.query === 'asc') {
        this.cardOrder.query = 'desc'
        this.data.sort((a, b) => a.card - b.card)
      } else {
        this.cardOrder.query = 'asc'
        this.data.sort((a, b) => b.card - a.card)
      }
    },
    orderByCs() {
      this.clearActiveFilters()
      this.cSOrder.isActive = true

      if (this.cSOrder.query === 'asc') {
        this.cSOrder.query = 'desc'
        this.data.sort((a, b) => a.amount - b.amount)
      } else {
        this.cSOrder.query = 'asc'
        this.data.sort((a, b) => b.amount - a.amount)
      }
    },
    orderByLoyalty() {
      this.clearActiveFilters()
      this.loyaltyOrder.isActive = true

      if (this.loyaltyOrder.query === 'asc') {
        this.loyaltyOrder.query = 'desc'
        this.data.sort((a, b) => a.loyalty - b.loyalty)
      } else {
        this.loyaltyOrder.query = 'asc'
        this.data.sort((a, b) => b.loyalty - a.loyalty)
      }
    },
    orderByToken() {
      this.clearActiveFilters()
      this.tokenOrder.isActive = true

      if (this.tokenOrder.query === 'asc') {
        this.tokenOrder.query = 'desc'
        this.data.sort((a, b) => a.token - b.token)
      } else {
        this.tokenOrder.query = 'asc'
        this.data.sort((a, b) => b.token - a.token)
      }
    },
    orderByTotal() {
      this.clearActiveFilters()
      this.totalOrder.isActive = true

      if (this.totalOrder.query === 'asc') {
        this.totalOrder.query = 'desc'
        this.data.sort((a, b) => a.total - b.total)
      } else {
        this.totalOrder.query = 'asc'
        this.data.sort((a, b) => b.total - a.total)
      }
    },
    clearActiveFilters() {
      this.locationNameOrder.isActive = false
      this.coinOrder.isActive = false
      this.smsOrder.isActive = false
      this.cSOrder.isActive = false
      this.loyaltyOrder.isActive = false
      this.totalOrder.isActive = false
    },
  },
}
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"card-toolbar mb-2 mr-2"},[_c('button',{staticClass:"btn btn-light-primary font-weight-bold px-10",on:{"click":_vm.toggleAdvancedView}},[_vm._v(" "+_vm._s(_vm.isAdvancedView ? _vm.$t('GENERAL.SIMPLE_VIEW') : _vm.$t('GENERAL.ADVANCED_VIEW'))+" ")])]),_c('div',{staticClass:"card-toolbar mb-2"},[_c('button',{staticClass:"btn btn-light-primary font-weight-bold px-10",on:{"click":_vm.toggleLocationsWithoutTraffic}},[_vm._v(" "+_vm._s(_vm.isLocationsWithoutTrafficVisible ? 'Hide' : 'Show')+" locations without traffic ")])])]),_c('div',{staticClass:"table-responsive mb-0 pb-0"},[(_vm.isGenerated)?_c('table',{staticClass:"table table-head-custom table-vertical-center table-head-bg"},[_c('thead',[_c('tr',{staticClass:"text-uppercase"},[_c('th',{staticClass:"pl-6"},[_c('span',{staticClass:"cursor-pointer",on:{"click":_vm.orderByLocationName}},[_c('span',{staticClass:"pt-2",class:{ 'text-primary': _vm.locationNameOrder.isActive }},[_vm._v(" "+_vm._s(_vm.$t('COMPANIES.LOCATION_NAME'))+" ")]),_c('span',{staticClass:"svg-icon svg-icon-sm pl-2",class:{
                  'svg-icon-primary': _vm.locationNameOrder.isActive,
                }},[(_vm.locationNameOrder.query === 'asc')?_c('inline-svg',{attrs:{"src":"media/svg/icons/Navigation/Down-2.svg"}}):_c('inline-svg',{attrs:{"src":"media/svg/icons/Navigation/Up-2.svg"}})],1)])]),(_vm.currentUserCompanyModule[0] === 'gaming')?_c('th',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.$t('GENERAL.LESSOR'))+" ")]):_vm._e(),(
              (_vm.currentUserCompanyModule[0] === 'gaming' ||
                _vm.currentUserCompanyModule[0] === 'parking') &&
              _vm.isAdvancedView
            )?_c('th',{staticClass:"text-right"},[_vm._v(" Netto Coin ")]):_vm._e(),(_vm.currentUserCompanyModule[0] === 'gaming')?_c('th',{staticClass:"text-right cursor-pointer"},[_c('span',{staticClass:"cursor-pointer",on:{"click":_vm.orderByCoin}},[_c('span',{staticClass:"pt-2",class:{ 'text-primary': _vm.coinOrder.isActive }},[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Brutto Coin'),expression:"'Brutto Coin'",modifiers:{"hover":true}}]},[_vm._v(_vm._s(_vm.$t('GENERAL.COIN')))])]),_c('span',{staticClass:"svg-icon svg-icon-sm pl-2",class:{
                  'svg-icon-primary': _vm.coinOrder.isActive,
                }},[(_vm.coinOrder.query === 'asc')?_c('inline-svg',{attrs:{"src":"media/svg/icons/Navigation/Down-2.svg"}}):_c('inline-svg',{attrs:{"src":"media/svg/icons/Navigation/Up-2.svg"}})],1)])]):_vm._e(),(_vm.currentUserCompanyModule[0] === 'gaming' && _vm.isAdvancedView)?_c('th',{staticClass:"text-right"},[_vm._v(" Netto SMS ")]):_vm._e(),(_vm.currentUserCompanyModule[0] === 'gaming')?_c('th',{staticClass:"text-right cursor-pointer"},[_c('span',{staticClass:"cursor-pointer",on:{"click":_vm.orderBySms}},[_c('span',{staticClass:"pt-2",class:{ 'text-primary': _vm.smsOrder.isActive }},[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Brutto SMS'),expression:"'Brutto SMS'",modifiers:{"hover":true}}]},[_vm._v("SMS")])]),_c('span',{staticClass:"svg-icon svg-icon-sm pl-2",class:{
                  'svg-icon-primary': _vm.smsOrder.isActive,
                }},[(_vm.smsOrder.query === 'asc')?_c('inline-svg',{attrs:{"src":"media/svg/icons/Navigation/Down-2.svg"}}):_c('inline-svg',{attrs:{"src":"media/svg/icons/Navigation/Up-2.svg"}})],1)])]):_vm._e(),(_vm.currentUserCompanyModule[0] === 'gaming' && _vm.isAdvancedView)?_c('th',{staticClass:"text-right"},[_vm._v(" Netto Card ")]):_vm._e(),(_vm.currentUserCompanyModule[0] === 'gaming')?_c('th',{staticClass:"text-right cursor-pointer"},[_c('span',{staticClass:"cursor-pointer",on:{"click":_vm.orderBySms}},[_c('span',{staticClass:"pt-2",class:{ 'text-primary': _vm.smsOrder.isActive }},[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Brutto Card'),expression:"'Brutto Card'",modifiers:{"hover":true}}]},[_vm._v("Card")])]),_c('span',{staticClass:"svg-icon svg-icon-sm pl-2",class:{
                  'svg-icon-primary': _vm.smsOrder.isActive,
                }},[(_vm.smsOrder.query === 'asc')?_c('inline-svg',{attrs:{"src":"media/svg/icons/Navigation/Down-2.svg"}}):_c('inline-svg',{attrs:{"src":"media/svg/icons/Navigation/Up-2.svg"}})],1)])]):_vm._e(),(_vm.currentUserCompanyModule[0] === 'gaming' && _vm.isAdvancedView)?_c('th',{staticClass:"text-right"},[_vm._v(" Netto CSC ")]):_vm._e(),(
              (_vm.currentUserCompanyModule[0] === 'gaming' ||
                _vm.currentUserCompanyModule[0] === 'parking') &&
              _vm.isAdvancedView
            )?_c('th',{staticClass:"text-right"},[_vm._v(" Pdv CSC ")]):_vm._e(),_c('th',{staticClass:"text-right cursor-pointer"},[_c('span',{staticClass:"cursor-pointer",on:{"click":_vm.orderByCs}},[_c('span',{staticClass:"pt-2",class:{ 'text-primary': _vm.cSOrder.isActive }},[(_vm.currentUserCompanyModule[0] === 'gaming')?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Brutto Coin + SMS + Card'),expression:"'Brutto Coin + SMS + Card'",modifiers:{"hover":true}}]},[_vm._v("CSC")]):_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Brutto Total'),expression:"'Brutto Total'",modifiers:{"hover":true}}]},[_vm._v(_vm._s(_vm.$t('GENERAL.TOTAL')))])]),_c('span',{staticClass:"svg-icon svg-icon-sm pl-2",class:{
                  'svg-icon-primary': _vm.cSOrder.isActive,
                }},[(_vm.cSOrder.query === 'asc')?_c('inline-svg',{attrs:{"src":"media/svg/icons/Navigation/Down-2.svg"}}):_c('inline-svg',{attrs:{"src":"media/svg/icons/Navigation/Up-2.svg"}})],1)])]),(_vm.currentUserCompanyModule[0] === 'gaming')?_c('th',{staticClass:"text-right"},[_c('span',{staticClass:"cursor-pointer",on:{"click":_vm.orderByLoyalty}},[_c('span',{staticClass:"pt-2",class:{ 'text-primary': _vm.loyaltyOrder.isActive }},[_c('span',[_vm._v("Loyalty")])]),_c('span',{staticClass:"svg-icon svg-icon-sm pl-2",class:{
                  'svg-icon-primary': _vm.loyaltyOrder.isActive,
                }},[(_vm.loyaltyOrder.query === 'asc')?_c('inline-svg',{attrs:{"src":"media/svg/icons/Navigation/Down-2.svg"}}):_c('inline-svg',{attrs:{"src":"media/svg/icons/Navigation/Up-2.svg"}})],1)])]):_vm._e(),(
              _vm.isTokenColumnVisible && _vm.currentUserCompanyModule[0] === 'gaming'
            )?_c('th',{staticClass:"text-right"},[_c('span',{staticClass:"cursor-pointer",on:{"click":_vm.orderByToken}},[_c('span',{staticClass:"pt-2",class:{ 'text-primary': _vm.tokenOrder.isActive }},[_c('span',[_vm._v("Token")])]),_c('span',{staticClass:"svg-icon svg-icon-sm pl-2",class:{
                  'svg-icon-primary': _vm.tokenOrder.isActive,
                }},[(_vm.tokenOrder.query === 'asc')?_c('inline-svg',{attrs:{"src":"media/svg/icons/Navigation/Down-2.svg"}}):_c('inline-svg',{attrs:{"src":"media/svg/icons/Navigation/Up-2.svg"}})],1)])]):_vm._e(),(_vm.currentUserCompanyModule[0] === 'gaming')?_c('th',{staticClass:"text-right cursor-pointer pr-6"},[_c('span',{staticClass:"cursor-pointer",on:{"click":_vm.orderByTotal}},[_c('span',{staticClass:"pt-2",class:{ 'text-primary': _vm.totalOrder.isActive }},[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Coin + SMS + Loyalty'),expression:"'Coin + SMS + Loyalty'",modifiers:{"hover":true}}]},[_vm._v(_vm._s(_vm.$t('GENERAL.TOTAL')))])]),_c('span',{staticClass:"svg-icon svg-icon-sm pl-2",class:{
                  'svg-icon-primary': _vm.totalOrder.isActive,
                }},[(_vm.totalOrder.query === 'asc')?_c('inline-svg',{attrs:{"src":"media/svg/icons/Navigation/Down-2.svg"}}):_c('inline-svg',{attrs:{"src":"media/svg/icons/Navigation/Up-2.svg"}})],1)])]):_vm._e()])]),_c('tbody',[_vm._l((_vm.data.filter(
            (d) => this.isLocationsWithoutTrafficVisible || d.total !== 0
          )),function(location,key){return _c('tr',{key:key + '_' + key},[_c('td',{staticClass:"pl-6",class:{ 'border-top-0': key === 0 }},[_c('span',{staticClass:"text-dark-75 font-weight-bolder mb-1 font-size-lg"},[_vm._v(_vm._s(location.location))])]),(_vm.currentUserCompanyModule[0] === 'gaming')?_c('td',{staticClass:"text-right",class:{ 'border-top-0': key === 0 }},[_vm._v(" "+_vm._s(location.lessor)+" ")]):_vm._e(),(
              (_vm.currentUserCompanyModule[0] === 'gaming' ||
                _vm.currentUserCompanyModule[0] === 'parking') &&
              _vm.isAdvancedView
            )?_c('td',{staticClass:"text-right",class:{ 'border-top-0': key === 0 }},[(location.netCoin !== 0)?_c('span',[_vm._v(_vm._s(_vm._f("currencyFormat")(location.netCoin))+" ")]):_c('span',[_vm._v("0 €")])]):_vm._e(),(_vm.currentUserCompanyModule[0] === 'gaming')?_c('td',{staticClass:"text-right",class:{ 'border-top-0': key === 0 }},[(location.coin !== 0)?_c('span',[_vm._v(_vm._s(_vm._f("currencyFormat")(location.coin))+" ")]):_c('span',[_vm._v("0 €")])]):_vm._e(),(_vm.currentUserCompanyModule[0] === 'gaming' && _vm.isAdvancedView)?_c('td',{staticClass:"text-right",class:{ 'border-top-0': key === 0 }},[(location.netSms !== 0)?_c('span',[_vm._v(_vm._s(_vm._f("currencyFormat")(location.netSms))+" ")]):_c('span',[_vm._v("0 €")])]):_vm._e(),(_vm.currentUserCompanyModule[0] === 'gaming')?_c('td',{staticClass:"text-right",class:{ 'border-top-0': key === 0 }},[(location.sms !== 0)?_c('span',[_vm._v(_vm._s(_vm._f("currencyFormat")(location.sms)))]):_c('span',[_vm._v("0 €")])]):_vm._e(),(_vm.currentUserCompanyModule[0] === 'gaming' && _vm.isAdvancedView)?_c('td',{staticClass:"text-right",class:{ 'border-top-0': key === 0 }},[(location.netCard !== 0)?_c('span',[_vm._v(_vm._s(_vm._f("currencyFormat")(location.netCard))+" ")]):_c('span',[_vm._v("0 €")])]):_vm._e(),(_vm.currentUserCompanyModule[0] === 'gaming')?_c('td',{staticClass:"text-right",class:{ 'border-top-0': key === 0 }},[(location.card !== 0)?_c('span',[_vm._v(_vm._s(_vm._f("currencyFormat")(location.card)))]):_c('span',[_vm._v("0 €")])]):_vm._e(),(_vm.currentUserCompanyModule[0] === 'gaming' && _vm.isAdvancedView)?_c('td',{staticClass:"text-right",class:{ 'border-top-0': key === 0 }},[(location.net !== 0)?_c('span',[_vm._v(_vm._s(_vm._f("currencyFormat")(location.net)))]):_c('span',[_vm._v("0 €")])]):_vm._e(),(
              (_vm.currentUserCompanyModule[0] === 'gaming' ||
                _vm.currentUserCompanyModule[0] === 'parking') &&
              _vm.isAdvancedView
            )?_c('td',{staticClass:"text-right",class:{ 'border-top-0': key === 0 }},[(location.pdv !== 0)?_c('span',[_vm._v(_vm._s(_vm._f("currencyFormat")(location.pdv)))]):_c('span',[_vm._v("0 €")])]):_vm._e(),(_vm.currentUserCompanyModule[0] === 'gaming')?_c('td',{staticClass:"text-dark-75 font-weight-bolder font-italic text-right pr-6",class:{ 'border-top-0': key === 0 }},[(location.csc !== 0)?_c('span',[_vm._v(_vm._s(_vm._f("currencyFormat")(location.csc)))]):_c('span',[_vm._v("0 €")])]):_vm._e(),(_vm.currentUserCompanyModule[0] === 'gaming')?_c('td',{staticClass:"text-right",class:{ 'border-top-0': key === 0 }},[(location.loyalty !== 0)?_c('span',[_vm._v(_vm._s(_vm._f("currencyFormat")(location.loyalty))+" ")]):_c('span',[_vm._v("0 €")])]):_vm._e(),(
              _vm.isTokenColumnVisible && _vm.currentUserCompanyModule[0] === 'gaming'
            )?_c('td',{staticClass:"text-right",class:{ 'border-top-0': key === 0 }},[(location.token !== 0)?_c('span',[_vm._v(_vm._s(location.token)+" ")]):_c('span',[_vm._v("0")])]):_vm._e(),(
              _vm.currentUserCompanyModule[0] === 'gaming' ||
              _vm.currentUserCompanyModule[0] === 'parking'
            )?_c('td',{staticClass:"text-dark-75 font-weight-bolder font-italic text-right pr-6",class:{ 'border-top-0': key === 0 }},[(location.total !== 0)?_c('span',[_vm._v(_vm._s(location.total)+" ")]):_c('span',[_vm._v("0 €")])]):_vm._e()])}),_c('tr',[_c('td',{staticClass:"pt-10 pl-6 font-size-lg font-weight-bolder text-uppercase"},[_vm._v(" "+_vm._s(_vm.$t('GENERAL.TOTAL'))+": ")]),(_vm.currentUserCompanyModule[0] === 'gaming')?_c('td'):_vm._e(),(
              (_vm.currentUserCompanyModule[0] === 'gaming' ||
                _vm.currentUserCompanyModule[0] === 'parking') &&
              _vm.isAdvancedView
            )?_c('td',{staticClass:"pt-10 font-size-lg text-right font-weight-bolder text-uppercase"},[(_vm.generateReportNetCoin !== 0)?_c('span',[_vm._v(_vm._s(_vm._f("currencyFormat")(_vm.generateReportNetCoin))+" ")]):_c('span',[_vm._v("0 €")])]):_vm._e(),(_vm.currentUserCompanyModule[0] === 'gaming')?_c('td',{staticClass:"pt-10 font-size-lg text-right font-weight-bolder text-uppercase"},[(_vm.generateReportCoin !== 0)?_c('span',[_vm._v(_vm._s(_vm._f("currencyFormat")(_vm.generateReportCoin))+" ")]):_c('span',[_vm._v("0 €")])]):_vm._e(),(_vm.currentUserCompanyModule[0] === 'gaming' && _vm.isAdvancedView)?_c('td',{staticClass:"pt-10 font-size-lg text-right font-weight-bolder text-uppercase"},[(_vm.generateReportNetSms !== 0)?_c('span',[_vm._v(_vm._s(_vm._f("currencyFormat")(_vm.generateReportNetSms)))]):_c('span',[_vm._v("0 €")])]):_vm._e(),(_vm.currentUserCompanyModule[0] === 'gaming')?_c('td',{staticClass:"pt-10 font-size-lg text-right font-weight-bolder text-uppercase"},[(_vm.generateReportSms !== 0)?_c('span',[_vm._v(_vm._s(_vm._f("currencyFormat")(_vm.generateReportSms))+" ")]):_c('span',[_vm._v("0 €")])]):_vm._e(),(_vm.currentUserCompanyModule[0] === 'gaming' && _vm.isAdvancedView)?_c('td',{staticClass:"pt-10 font-size-lg text-right font-weight-bolder text-uppercase"},[(_vm.generateReportNetCard !== 0)?_c('span',[_vm._v(_vm._s(_vm._f("currencyFormat")(_vm.generateReportNetCard)))]):_c('span',[_vm._v("0 €")])]):_vm._e(),(_vm.currentUserCompanyModule[0] === 'gaming')?_c('td',{staticClass:"pt-10 font-size-lg text-right font-weight-bolder text-uppercase"},[(_vm.generateReportCard !== 0)?_c('span',[_vm._v(_vm._s(_vm._f("currencyFormat")(_vm.generateReportCard))+" ")]):_c('span',[_vm._v("0 €")])]):_vm._e(),(_vm.currentUserCompanyModule[0] === 'gaming' && _vm.isAdvancedView)?_c('td',{staticClass:"pt-10 font-size-lg text-right font-weight-bolder text-uppercase"},[_vm._v(" "+_vm._s(_vm._f("currencyFormat")(_vm.generateReportNet))+" ")]):_vm._e(),(
              (_vm.currentUserCompanyModule[0] === 'gaming' ||
                _vm.currentUserCompanyModule[0] === 'parking') &&
              _vm.isAdvancedView
            )?_c('td',{staticClass:"pt-10 font-size-lg text-right font-weight-bolder text-uppercase"},[_vm._v(" "+_vm._s(_vm._f("currencyFormat")(_vm.generateReportPdv))+" ")]):_vm._e(),(_vm.currentUserCompanyModule[0] === 'gaming')?_c('td',{staticClass:"pt-10 font-size-lg text-right font-weight-bolder text-uppercase"},[_vm._v(" "+_vm._s(_vm._f("currencyFormat")(_vm.generateReportCsc))+" ")]):_vm._e(),(_vm.currentUserCompanyModule[0] === 'gaming')?_c('td',{staticClass:"pt-10 font-size-lg text-right font-weight-bolder text-uppercase pr-6"},[(_vm.generateReportLoyalty !== 0)?_c('span',[_vm._v(_vm._s(_vm._f("currencyFormat")(_vm.generateReportLoyalty)))]):_c('span',[_vm._v("0 €")])]):_vm._e(),(
              _vm.isTokenColumnVisible && _vm.currentUserCompanyModule[0] === 'gaming'
            )?_c('td',{staticClass:"pt-10 font-size-lg text-right font-weight-bolder text-uppercase"},[(_vm.generateReportToken !== 0)?_c('span',[_vm._v(_vm._s(_vm.generateReportToken))]):_c('span',[_vm._v("0")])]):_vm._e(),(
              _vm.currentUserCompanyModule[0] === 'gaming' ||
              _vm.currentUserCompanyModule[0] === 'parking'
            )?_c('td',{staticClass:"pt-10 font-size-lg text-right font-weight-bolder text-uppercase pr-6"},[(_vm.generateReportTotal !== 0)?_c('span',[_vm._v(_vm._s(_vm._f("currencyFormat")(_vm.generateReportTotal)))]):_c('span',[_vm._v("0 €")])]):_vm._e()])],2)]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }